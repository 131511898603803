import {me, isAdmin} from './components/common/callApi';
import {useEffect, useState, createContext, useContext} from 'react';

const authContext = createContext({});

function useAuth() {
   const [authenticated, setAuthenticated] = useState(false);
   const [admin, setAdmin] = useState(false);
   const [user, setUser] = useState(() => 
     window.localStorage.getItem('user') || null
   );
   const signout = () => {
    setUser(null);
    setAuthenticated(false);
  }

   useEffect(()=> {
    me()
    .then((res) => {
      if(res){
        if(res.data){
          setUser(res.data);
          window.localStorage.setItem('user', res.data);
          setAuthenticated(true);
        } else {
          setUser(null);
          window.localStorage.removeItem('user');
          setAuthenticated(false);
        }
      }
    });
   }, []);

   useEffect(()=> {
      isAdmin()
      .then((res) => {
        if(res.data){
          if(res.data.isAdmin){
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        }
      });
   }, [authenticated]);

   return {
    authenticated,
    admin,
    user,
    signout
  };
}

export function AuthProvider({ children }) {
   const auth = useAuth();

   return (
     <authContext.Provider value={auth}>
       {children}
     </authContext.Provider>
   );
}

export const AuthConsumer = () => {
   return useContext(authContext);
}