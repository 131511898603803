import { useState, useMemo, useEffect } from "react";
import { Col, Container, Row, Form, Button, Alert } from "react-bootstrap";
import CardCommunication from "./CardCommunication/CardCommunication";
import { Formik } from "formik";
import * as yup from "yup";
import { callApiGetCommunications } from "../../common/callApi";
import Loading from "../../common/Loading/Loading";
import { useTranslation } from "react-i18next";
import { AuthConsumer } from "../../../AuthContext";
import Pagination from "../../common/Pagination/Pagination";
import { checkValidCodiceFiscaleOrPartitaIVA, checkValidCodiceFiscale, uppercase, trim } from "../../common/utils";

function MyCommunicationsScreen() {
  const { t } = useTranslation();
  const { user } = AuthConsumer();

  yup.addMethod(
    yup.string,
    "checkValidCodiceFiscaleOrPartitaIVA",
    checkValidCodiceFiscaleOrPartitaIVA
  );
  yup.addMethod(
    yup.string, 
    "checkValidCodiceFiscale", 
    checkValidCodiceFiscale
  );

  const schema = yup.object().shape({
    codiceFiscaleDatore: yup
      .string()
      .max(16, t('formScreen.validationMessages.codiceFiscalePartitaIvaMax'))
      .min(11, t('formScreen.validationMessages.codiceFiscalePartitaIvaMin'))
      .uppercase()
      .strict()
      .trim()
      .checkValidCodiceFiscaleOrPartitaIVA(
        t("formScreen.validationMessages.codiceFiscaleRequired")
      ),
    codiceFiscaleLavoratore: yup
    .string()
    .max(16, t('formScreen.validationMessages.codiceFiscalePartitaIvaMax'))
    .min(11, t('formScreen.validationMessages.codiceFiscalePartitaIvaMin'))
    .uppercase()
    .strict()
    .trim()
    .checkValidCodiceFiscale(
      t("formScreen.validationMessages.codiceFiscaleRequired")
    ),
    fromDate: yup
      .date()
      .min(new Date(1900, 1, 1), t("formScreen.validationMessages.startDateRequired"))
      .max(new Date(2099, 12, 31), t("formScreen.validationMessages.startDateRequired")),
    toDate: yup
      .date()
      .min(new Date(1900, 1, 1), t("formScreen.validationMessages.startDateRequired"))
      .max(new Date(2099, 12, 31), t("formScreen.validationMessages.startDateRequired")),
  });

  const [loading, setLoading] = useState({
    is: false,
    text: ""
  });
  const [error, setError] = useState({
    is: false,
    status: null,
    text: ""
  });
  const [communicationsData, setCommunicationsData] = useState({
    items: [],
    total: 0,
  });

  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
    codiceFiscaleDatore: "",
    codiceFiscaleLavoratore: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [noItemsFound, setNoItemsFound] = useState(false);

  const getCommunications = (page) => {
    callApiGetCommunications(
      user?.fiscalNumber,
      formData.codiceFiscaleDatore,
      formData.codiceFiscaleLavoratore,
      formData.fromDate,
      formData.toDate,
      PageSize,
      page ? (page - 1) * PageSize : 0
    ).then((response) => {
      setIsSubmitted(false);
      setNoItemsFound(false);
      if (response === undefined) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: null,
          text: t('myCommunicationScreen.loadingError'),
        });
      } else if (response.status === 200) {
        setCommunicationsData(response.data);
        if(response.data?.total === 0){
          setNoItemsFound(true);
        }
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: false,
          status: null,
          text: "",
        });
      } else if (response.status === 404) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: response.status,
          text: t('myCommunicationScreen.noCommunicationFound'),
        });
      } else if (response.status === 401) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: response.status,
          text: t('api.unauthorized'),
        });
      } else {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: response.status,
          text: t('myCommunicationScreen.loadingError'),
        });
      }
    });
  };

  useEffect(() => {
    if (isSubmitted) {
      getCommunications();
    }
  }, [formData, isSubmitted]);

  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    return communicationsData.items;
  }, [communicationsData]);

  return (
    <Container className="containerBody" fluid>
      <Row>
        <h2 xs="12" className="mb-3">
          {t('comu.com')}
        </h2>
      </Row>
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => {
          setError({
            is: false,
            status: null,
            text: "",
          });
          setLoading({
            is: true,
            text: "Caricamento comunicazioni in corso...",
          });
          setTimeout(() => {
            actions.setSubmitting(false);
            setFormData({
              fromDate: values.fromDate,
              toDate: values.toDate,
              codiceFiscaleDatore: values.codiceFiscaleDatore,
              codiceFiscaleLavoratore: values.codiceFiscaleLavoratore,
            });
            setIsSubmitted(true);
          }, 1000);
        }}
        initialValues={{
          fromDate: "",
          toDate: "",
          codiceFiscaleDatore: "",
          codiceFiscaleLavoratore: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-2">
              <Form.Group as={Col} md="3" controlId="validationFormik01">
                <Form.Label>Codice Fiscale Datore</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Codice Fiscale Datore"
                  value={values.codiceFiscaleDatore}
                  name="codiceFiscaleDatore"
                  maxLength={16}
                  minLength={11}
                  onChange={(e) => {
                    uppercase(e);
                    trim(e);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  isInvalid={errors.codiceFiscaleDatore && touched.codiceFiscaleDatore}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-codiceFiscaleDatore"
                >
                  {errors.codiceFiscaleDatore}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationFormik01">
                <Form.Label>Codice Fiscale Lavoratore</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Codice Fiscale Lavoratore"
                  value={values.codiceFiscaleLavoratore}
                  name="codiceFiscaleLavoratore"
                  maxLength={16}
                  // minLength={11}
                  onChange={(e) => {
                    uppercase(e);
                    trim(e);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  isInvalid={errors.codiceFiscaleLavoratore && touched.codiceFiscaleLavoratore}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-codiceFiscaleLavoratore"
                >
                  {errors.codiceFiscaleLavoratore}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationFormik02">
                <Form.Label>Da</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="From"
                  value={values.fromDate}
                  name="fromDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.fromDate && touched.fromDate}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-fromDate"
                >
                  {errors.fromDate}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationFormik03">
                <Form.Label>A</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="To"
                  value={values.toDate}
                  name="toDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.toDate && touched.toDate}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-toDate"
                >
                  {errors.toDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="d-grid gap-2">
              <Button
                size="md"
                className="mb-3"
                key="myCommunicationsSearch"
                id="myCommunicationsSearch"
                type="submit"
                disabled={loading.is}
              >
                {loading.is ? <Loading size="sm" /> : ""}
                {loading.is ? " " + loading.text : "Cerca"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {error.is ? (
        error.status === 404 ? (
          <Alert variant="warning">{error.text}</Alert>
        ) : (
          <Alert variant="danger">{error.text}</Alert>
        )
      ) : (
        ""
      )}
      <Row xs="1" className="g-4">
        
        {
          currentTableData?.length > 0
          ? currentTableData?.map((el) => (
              <CardCommunication dataComunication={el} />
            ))
          :
          noItemsFound ?
          <Col>
            <div className="text-center">
              {t('myCommunicationScreen.noCommunicationFound')}
            </div>
          </Col> :
          ""
        }
        
      </Row>
      <Container>
        <Row>
          <Col>
            <Pagination
              currentPage={currentPage}
              totalCount={communicationsData?.total}
              pageSize={PageSize}
              onPageChange={(page) => {
                setCurrentPage(page);
                getCommunications(page);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default MyCommunicationsScreen;
