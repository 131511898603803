import { Col, Container, Row, Button, Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../../context";
import { useState, useEffect } from "react";
import {
  callApiGetComunicationById,
  callApiGetGeneratePDF,
} from "../../../common/callApi";
import { useInterval } from "../../../../hooks/useInterval";

function StatusRequestPage({ idNewComunication }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading, setLoading, error, setError } = useGlobalContext();
  const [isRunning, setIsRunning] = useState(true);
  const [communication, setCommunication] = useState({});

  useInterval(
    async () => {

      if(isRunning){
        // Get JSON data from API
        fnGetCommunication();
      }
    },
    isRunning ? 3000 : null
  );

  const clickDownloadPDF = () => {
    setError({
      is: false,
      status: null,
      text: "",
    });
    setLoading({
      is: true,
      text: t("myCommunicationScreen.downloadingPdf"),
    });
    setTimeout(() => {
      callApiGetGeneratePDF(idNewComunication).then((response) => {
        if (response === undefined) {
          setError({
            is: true,
            status: null,
            text: t("errorDownloadingPdf"),
          });
          setLoading({
            is: false,
            text: "",
          });
        } else if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Ricevuta - "
              .concat(communication.codiceComunicazione)
              .concat(".pdf")
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setError({
            is: false,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        } else {
          setError({
            is: true,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        }
      });
    }, 0);
  };

  useEffect(() => {
    setLoading({
      is: true,
      text: t("loading"),
    });
    setError({
      is: false,
      status: null,
      text: "",
    });
  }, []);

  useEffect(() => {
    if(!isRunning) {
      setLoading({
        is: false
      });
    }
  }, [isRunning]);

  function trySetError(errorIs, errorText) {
    if(!error.is){
      setError({
        is: errorIs,
        status: null,
        text: errorText
      });
    }
  }

  /***
   * fnGetCommunication 
   * 
   * Call API function to retrieve single JSON document.
   * */
  const fnGetCommunication = () => {
    setTimeout(() => {
      callApiGetComunicationById(idNewComunication)
        .then((response) => {
          if (response === undefined) {
            // Stop polling
            setIsRunning(false);
            // Loading.Is: false, Loading.Text: '', Error.Is: false, Text: ''
            trySetError(true, t("statusRequest.getOperationFailed"));
            
          } else if (response?.status === 200) {
            // Set json data
            setCommunication(response.data);
            // Stop polling
            setIsRunning(false);
            
          } else {
              console.error(response);
          }
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            // On 404 status code polling should continue
            setIsRunning(true);
            
          } else {
            // Stop polling
            setIsRunning(false);
            // Loading.Is: false, Loading.Text: '', Error.Is: false, Text: ''
            trySetError(true, t('statusRequest.getOperationFailed'));
            
          }
        });
    }, 0);
  };

  return (
    <Container className="containerBody" fluid>
      {loading.is ? (
        <Row>
          <Col xs="12">
            <Alert variant="primary">
              <Alert.Heading>{t("statusRequest.getOperation")}</Alert.Heading>
              <p>{t("statusRequest.getOperationDetail")}</p>
              <hr />
              <p className="text-center">
                <Spinner variant="primary" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </p>
            </Alert>
          </Col>
        </Row>
      ) : error.is ? (
        <>
          <Row>
            <Col xs="12">
              <Alert variant="warning">
                <Alert.Heading>{error.text}</Alert.Heading>
              </Alert>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col xs="12" className="mb-3">
              <Alert variant="success">
                <Alert.Heading>
                  {t("statusRequest.savingOperationSucceded")}
                </Alert.Heading>
                <hr />
                <p className="mb-0">{t("statusRequest.hint")}</p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="d-grid mb-2">
              <Button
                variant="primary"
                key={"show-detail-" + idNewComunication}
                id={"show-detail-" + idNewComunication}
                onClick={() =>
                  navigate("/mycommunications/" + idNewComunication)
                }
              >
                {t("statusRequest.showDetails")}
              </Button>
            </Col>
            <Col md="6" className="d-grid mb-2">
              <Button
                variant="secondary"
                key={"save-pdf-" + idNewComunication}
                id={"save-pdf-" + idNewComunication}
                onClick={() => clickDownloadPDF()}
                disabled={loading.is}
              >
                <i className="bi bi-file-earmark-arrow-down"></i>
                {t("downloadPdf")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default StatusRequestPage;
