import CodiceFiscale from "codice-fiscale-js";

function extractConsonants(str) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '')
}

function extractVowels (str) {
  return str.replace(/[^AEIOU]/gi, '')
}

function ComputeSurnameCode(surname) {
  return `${extractConsonants(surname)}${extractVowels(surname)}XXX`.substring(0, 3).toUpperCase()
}

function ComputeNameCode(name){
  let codNome = extractConsonants(name)
  if (codNome.length >= 4) {
      codNome = codNome.charAt(0) + codNome.charAt(2) + codNome.charAt(3)
  } 
  else {
      codNome += `${extractVowels(name)}XXX`
      codNome = codNome.substring(0, 3)
  }

  return codNome.toUpperCase()
}

function IsValideCFForFullName(cf, name, surname) {
  return `${cf.substring(0, 6).toUpperCase()}` == `${ComputeSurnameCode(surname) + ComputeNameCode(name)}`;
}

export function uppercase(event) {
    if (typeof event.currentTarget.value !== "string") return event;

    event.currentTarget.value = event.currentTarget.value.toUpperCase();
    return event;
  }

export function trim(event) {
    if (typeof event.currentTarget.value !== "string") return event;

    event.currentTarget.value = event.currentTarget.value.replace(/\s+/g, "");
    return event;
  }

export function pivaChecksum(piva){
    var s = 0;
		for(var i = 0; i < 11; i++ ){
			var n = piva.charCodeAt(i) - "0".charCodeAt(0);
			if( (i & 1) === 1 ){
				n *= 2;
				if( n > 9 )
					n -= 9;
			}
			s += n;
		}

		return (s % 10 === 0);
  }

  /*
  * Il carattere numerico di controllo viene determinato nel modo seguente:
  * si sommano i valori di ciascuna delle cinque cifre di ordine dispari,partendo da sinistra;
  * si raddoppia ogni cifra di ordine pari e, se il risultato è un numero di due cifre, esso si riduce ad una sola sommando la cifra relativa alle decine e quella relativa alle unità; si sommano quindi tutti i precedenti risultati;
  * si determina il totale delle due somme di cui sopra;
  * si sottrae da dieci la cifra relativa alle unità del precedente totale. Il carattere di controllo è la cifra relativa alle unità del risultato.
  */
export function isCodiceFiscaleProvvisorio(cf){
    
  var sum_odds = 0;
  var sum_evens = 0;
  var sum = 0;
  for(var i = 0; i < 10; i++ ){
    if(i % 2 === 0){
      sum_evens += Number(cf.charAt(i));
    } else {
      const n = Number(cf.charAt(i)) * 2;
      var nStringata = n + "";
      sum_odds += nStringata.length === 2 ? Number(nStringata.charAt(0)) + Number(nStringata.charAt(1)) : n;
    }
  }

  sum = sum_odds + sum_evens;
  sum = sum + '';
  sum = 10 - Number(sum.charAt(1));
  sum += '';
  return Number(cf.charAt(10)) === Number(sum.charAt(sum.length - 1));
}

export function checkValidCodiceFiscaleOrPartitaIVA(message) {
    return this.test("checkValidCodiceFiscale", message, function (value) {
      const { path, createError } = this;

      if (value !== undefined) {
        if (value.length === 11 && Number(value)) {
          if(!pivaChecksum(value) && !isCodiceFiscaleProvvisorio(value)) {  
            return createError({
              path,
              message: message,
            });
          }

          return true;
        }         
        else if (!CodiceFiscale.check(value)) {
          return createError({
            path,
            message: message,
          });
        } 
        else {
          return true;
        }
      } 
      else {
        return true;
      }
    });
  }

export function checkValidCodiceFiscale(defasultErrorMessage, fullNameMismatchErrorMessage) {
    return this.test("checkValidCodiceFiscale", defasultErrorMessage, function (value) {
      const { path, createError } = this;    
      
      if (value !== undefined) {
        if(value.length === 11 && Number(value)){
          if(!isCodiceFiscaleProvvisorio(value)){
            return createError({
              path,
              message: defasultErrorMessage,
            });
          }
          
          return true;
        } 
        else if(this.parent.nomeLavoratore 
          && this.parent.nomeLavoratore.trim() != '' 
          && this.parent.cognomeLavoratore
          && this.parent.cognomeLavoratore.trim() != '' 
          && !IsValideCFForFullName(value, this.parent.nomeLavoratore , this.parent.cognomeLavoratore)) {
            return createError({
              path,
              message: fullNameMismatchErrorMessage,
            });
        }
        else if (!CodiceFiscale.check(value)) {
          return createError({
            path,
            message: defasultErrorMessage,
          });
        } 
        else {
          return true;
        }
      } 
      else {
        return true;
      }
    });
  }

  export function checkValidCodiceFiscaleSimple(message) {
    return this.test("checkValidCodiceFiscaleSimple", message, function (value) {
      const { path, createError } = this;

      if (value !== undefined) {
        if (!CodiceFiscale.check(value)) {
          return createError({
            path,
            message: message,
          });
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  }
