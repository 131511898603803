import { useEffect, useState, Suspense } from "react";
import { Col, Container, Row, Button, Form, Alert, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./FormScreen.module.css";
import { Formik } from "formik";
import * as yup from "yup";
import GDPRPage from "./GDPRPage/GDPRPage";
import Loading from "../../common/Loading/Loading";
import {
  callApiCreateNewComunication,
  getDenominazioneAzienda,
  getDenominazioneLavoratore,
  getCommunicationSubjects,
} from "../../common/callApi";
import StatusRequestPage from "./StatusRequestPage/StatusRequestPage";
import { useTranslation } from "react-i18next";
import { AuthConsumer } from "../../../AuthContext";
import ScrollToTop from "../../common/ScrollToTop/ScrollToTop";
import { checkValidCodiceFiscaleOrPartitaIVA, checkValidCodiceFiscale, uppercase, trim } from "../../common/utils";

function FFormScreen() {
  const { t } = useTranslation();

  const { user } = AuthConsumer();
  const [error, setError] = useState({
    is: false,
    status: null,
    text: ""
  });
  const [loading, setLoading] = useState({
    is: false,
    text: "",
  });

  
  yup.addMethod(
    yup.string,
    "checkValidCodiceFiscaleOrPartitaIVA",
    checkValidCodiceFiscaleOrPartitaIVA
  );
  yup.addMethod(
    yup.string, 
    "checkValidCodiceFiscale", 
    checkValidCodiceFiscale
  );

  const schema = yup.object().shape({
    codiceFiscaleDatoreDiLavoro: yup
      .string()
      .max(16, t('formScreen.validationMessages.codiceFiscalePartitaIvaMax'))
      .min(11, t('formScreen.validationMessages.codiceFiscalePartitaIvaMin'))
      .uppercase()
      .strict()
      .trim()
      .required(
        t("formScreen.validationMessages.codiceFiscaleRequired")
      )
      .checkValidCodiceFiscaleOrPartitaIVA(
        t("formScreen.validationMessages.codiceFiscaleRequired")
      ),
    denominazioneDatoreDiLavoro: yup
      .string()
      .max(100)
      .required(
        t("formScreen.validationMessages.denominazioneDatoreLavoroRequired")
      ),
    codiceFiscaleLavoratore: yup
      .string()
      .max(16, t('formScreen.validationMessages.codiceFiscalePartitaIvaMax'))
      .min(11, t('formScreen.validationMessages.codiceFiscalePartitaIvaMin'))
      .uppercase()
      .strict()
      .trim()
      .required(
        t("formScreen.validationMessages.codiceFiscaleRequired")
      )
      .checkValidCodiceFiscale(
        t("formScreen.validationMessages.codiceFiscaleRequired")
        , t("formScreen.validationMessages.codiceFiscaleFullNameMismatch")
      ),
    cognomeLavoratore: yup
      .string()
      .max(50)
      .required(t("formScreen.validationMessages.surnameRequired")),
    nomeLavoratore: yup
      .string()
      .max(50)
      .required(t("formScreen.validationMessages.firstnameRequired")),
    dataInizioRapportoDiLavoro: yup
      .date()
      .min(new Date(1900, 1, 1), t("formScreen.validationMessages.startDateRequired"))
      .max(new Date(2099, 12, 31), t("formScreen.validationMessages.startDateRequired"))
      .required(t("formScreen.validationMessages.startDateRequired")),
    motivoUrgenza: yup
      .string()
      .max(100)
      .required(t("formScreen.validationMessages.reasonRequired")),
    soggettoCheEffettuaLaComunicazione: yup.string(),
    codiceFiscaleDelSoggettoCheEffettuaLaComunicazione: yup
      .string()
      .when("soggettoCheEffettuaLaComunicazione", {
        is: (val) => val === undefined || val === "" || val === "000",
        then: yup.string(),
        otherwise: yup
          .string()
          .max(16, t('formScreen.validationMessages.codiceFiscalePartitaIvaMax'))
          .min(11, t('formScreen.validationMessages.codiceFiscalePartitaIvaMin'))
          .uppercase()
          .strict()
          .trim()
          .required(
            t("formScreen.validationMessages.codiceFiscaleRequired")
          )
          .checkValidCodiceFiscaleOrPartitaIVA(
            t("formScreen.validationMessages.codiceFiscaleRequired")
          ),
      }),
    emailDelSoggettoCheEffettuaLaComunicazione: yup
      .string()
      .max(80)
      .required(t("formScreen.validationMessages.soggettoEmailRequired"))
      .email(t("formScreen.validationMessages.soggettoEmailRequired")),
  });

  const [dataIdTestFormControl, setDataIdTestFormControl] = useState("enabledInput");
  const [showConfirmFormPreview, setShowConfirmFormPreview] = useState(false);
  const [screenConfirmFormStatus, setScreenConfirmFormStatus] = useState({
    show: false,
    idNewComunication: "",
    title: "",
    text: "",
  });
  const [acceptGDPR, setAcceptGDPR] = useState(false);
  const [formData, setFormData] = useState({
    codiceFiscaleDatoreDiLavoro: "",
    denominazioneDatoreDiLavoro: "",
    codiceFiscaleLavoratore: "",
    cognomeLavoratore: "",
    nomeLavoratore: "",
    dataInizioRapportoDiLavoro: "",
    motivoUrgenza: "",
    soggettoCheEffettuaLaComunicazione: "",
    codiceFiscaleDelSoggettoCheEffettuaLaComunicazione: "",
    emailDelSoggettoCheEffettuaLaComunicazione: ""
  });

  const [loadingDatore, setLoadingDatore] = useState({is: false, text: ""});
  const [loadingLavoratore, setLoadingLavoratore] = useState({is: false, text: ""});
  const [denominazioneAzienda, setDenominazioneAzienda] = useState("");
  const [denominazioneLavoratoreCognome, setDenominazioneLavoratoreCognome] =
    useState("");
  const [denominazioneLavoratoreNome, setDenominazioneLavoratoreNome] =
    useState("");

  function resetCFDatore(){
    setDenominazioneAzienda("");
  }

  function resetCFLavoratore(){
    setDenominazioneLavoratoreCognome("");
    setDenominazioneLavoratoreNome("");
  }

  const fnSearchForDenominazioneAzienda = (values) => {
    if (values) {
      setLoadingDatore({
        is: true,
        text: "",
      });
      setTimeout(() => {
        getDenominazioneAzienda(values).then((response) => {
          if (response === undefined || response.status !== 200) {
            setLoadingDatore({
              is: false,
              text: "",
            });
            setDenominazioneAzienda("");
          } else if (response.status === 200) {
            setLoadingDatore({
              is: false,
              text: "",
            });
            if(response?.data?.esito?.codice === 'E100' &&
               response?.data?.denominazioneCO !== null){
              setDenominazioneAzienda(response.data.denominazioneCO);
            }
          }
        });
      }, 0);
    }
  };

  const fnSearchForDenominazioneLavoratore = (values) => {
    if (values) {
        setLoadingLavoratore({
            is: true,
            text: "",
          });
        setTimeout(() => {
          getDenominazioneLavoratore(values).then((response) => {
            if (response === undefined || response.status !== 200) {
              setLoadingLavoratore({
                is: false,
                text: "",
              });
              setDenominazioneLavoratoreCognome("");
              setDenominazioneLavoratoreNome("");
            } else if (response.status === 200) {
              setLoadingLavoratore({
                is: false,
                text: "",
              });
              if(response?.data?.esito?.codice === 'E100' &&
                 response?.data?.co?.cognome !== null &&
                 response?.data?.co?.nome !== null){
                setDenominazioneLavoratoreCognome(response.data.co.cognome);
                setDenominazioneLavoratoreNome(response.data.co.nome);
              } 
            }
          });
      }, 0);
    }
  };

  const functionApiCreateNewComunication = (isDraft) => {
    setError({
      is: false,
      status: null,
      text: "",
    });
    setLoading({
      is: true,
      text: t("loading"),
    });
    setTimeout(() => {
      let jsonData = {
        dataInvio: new Date(),
        ...(formData.soggettoCheEffettuaLaComunicazione !== '' && formData.soggettoCheEffettuaLaComunicazione !== "000" ? {tipoDelegato: formData.soggettoCheEffettuaLaComunicazione} : null),
        emailDelegato: formData.emailDelSoggettoCheEffettuaLaComunicazione,
        currentUser: user?.fiscalNumber,
        emailCurrentUser: user?.email,
        motivoUrgenza: formData.motivoUrgenza,
        dataInizioRapporto: new Date(formData.dataInizioRapportoDiLavoro),
        lavoratore: {
          cognome: formData.cognomeLavoratore,
          nome: formData.nomeLavoratore,
          cfLavoratore: formData.codiceFiscaleLavoratore,
        },
        datoreLavoro: {
          cfDatoreLavoro: formData.codiceFiscaleDatoreDiLavoro,
          denominazione: formData.denominazioneDatoreDiLavoro,
        },
        send: !isDraft,
      };

      if (formData.soggettoCheEffettuaLaComunicazione !== "" && formData.soggettoCheEffettuaLaComunicazione !== "000") {
        jsonData["cfDelegato"] =
          formData.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione;
      }

      if (!isDraft) {
        callApiCreateNewComunication(jsonData)
        .then((response) => {
          if (response === undefined) {
            setLoading({
              is: false,
              text: "",
            });
            setError({
              is: true,
              status: null,
              text: t("formScreen.messages.savingOperationFailed"),
            });
          } else if (response.status === 200) {
            setScreenConfirmFormStatus({
              show: true,
              idNewComunication: response.data,
              title: t("formScreen.messages.sendOperationSucceded"),
              text: t("formScreen.messages.sendOperationSucceded"),
            });
            setLoading({
              is: false,
              text: "",
            });
            setError({
              is: false,
              status: null,
              text: "",
            });
          } else if (response.status === 401) {
            setLoading({
              is: false,
              text: "",
            });
            setError({
              is: false,
              status: null,
              text: t("api.unauthorized")
            });
          } else {
            setLoading({
              is: false,
              text: "",
            });
            setError({
              is: true,
              status: response.status,
              text: t("formScreen.messages.savingOperationFailed")
            });
          }
        });
      }
    }, 1000);
  };

  const [communicationSubjects, setCommunicationSubjects] = useState([
    {
      id: 0,
      value: "",
      text: "",
    },
  ]);
  const [isSubjectLoaded, setIsSubjectLoaded] = useState(false);
  useEffect(() => {
    getCommunicationSubjects()
      .then((response) => {
        if (response.data) {
          setIsSubjectLoaded(true);
          setCommunicationSubjects(response.data);
        }
      })
      .catch((error) => {
        console.error("fnSub", error);
      });
  }, []);

  const handleModify = () => {
    setShowConfirmFormPreview(false);
  }

  useEffect(() => {
    if (showConfirmFormPreview) {
      setDataIdTestFormControl("disabledInput");
    } else {
      setDataIdTestFormControl("enabledInput");
    }
  }, [showConfirmFormPreview]);

  if (acceptGDPR === false) {
    return <GDPRPage setAcceptGDPR={setAcceptGDPR} />;
  }
  if (screenConfirmFormStatus.show) {
    return (
      <StatusRequestPage
        idNewComunication={screenConfirmFormStatus.idNewComunication}
      />
    );
  }
  return (
    <Container className="containerBody" fluid>
      <>
      <ScrollToTop />
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => {
          setLoading({
            is: true,
            title: t("loadingcomunication"),
            text: "Caricamento anteprima comunicazione...",
          });
          setTimeout(() => {
            actions.setSubmitting(false);
            setShowConfirmFormPreview(true);
            setFormData({
              codiceFiscaleDatoreDiLavoro: values.codiceFiscaleDatoreDiLavoro,
              denominazioneDatoreDiLavoro: values.denominazioneDatoreDiLavoro,
              codiceFiscaleLavoratore: values.codiceFiscaleLavoratore,
              cognomeLavoratore: values.cognomeLavoratore,
              nomeLavoratore: values.nomeLavoratore,
              dataInizioRapportoDiLavoro: values.dataInizioRapportoDiLavoro,
              motivoUrgenza: values.motivoUrgenza,
              soggettoCheEffettuaLaComunicazione:
                values.soggettoCheEffettuaLaComunicazione,
              codiceFiscaleDelSoggettoCheEffettuaLaComunicazione:
                values.soggettoCheEffettuaLaComunicazione !== "" && values.soggettoCheEffettuaLaComunicazione !== "000"
                  ? values.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione
                  : "",
              emailDelSoggettoCheEffettuaLaComunicazione:
                values.emailDelSoggettoCheEffettuaLaComunicazione
            });
            setLoading({
              is: false,
              text: "",
            });
          }, 1000);
        }}
        initialValues={{
          codiceFiscaleDatoreDiLavoro: "",
          denominazioneDatoreDiLavoro: "",
          codiceFiscaleLavoratore: "",
          cognomeLavoratore: "",
          nomeLavoratore: "",
          dataInizioRapportoDiLavoro: "",
          motivoUrgenza: "",
          soggettoCheEffettuaLaComunicazione: "",
          codiceFiscaleDelSoggettoCheEffettuaLaComunicazione: "",
          emailDelSoggettoCheEffettuaLaComunicazione: ""
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col xs="12" className={styles.testataForm}>
                {t("formScreen.validationMessages.title")}
              </Col>
            </Row>
            <Row>
              <Col xs="12" className={styles.titolettoForm}>
                {t("formScreen.validationMessages.title3")}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} md={3} className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.Datore1")}
                </Form.Label>
              </Col>
              <Col xs={10} md={3} className="mt-2">
                <Form.Control
                  type="text"
                  placeholder={t("formScreen.validationMessages.PlaceH1")}
                  readOnly={showConfirmFormPreview}
                  value={values.codiceFiscaleDatoreDiLavoro}
                  name="codiceFiscaleDatoreDiLavoro"
                  maxLength={16}
                  minLength={11}
                  onChange={(e) => {
                    uppercase(e);
                    trim(e);
                    resetCFDatore();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.codiceFiscaleDatoreDiLavoro &&
                    touched.codiceFiscaleDatoreDiLavoro
                  }
                  data-id-test={
                    "codiceFiscaleDatoreDiLavoro-" + dataIdTestFormControl
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-codiceFiscaleDatoreDiLavoro"
                >
                  {errors.codiceFiscaleDatoreDiLavoro}
                </Form.Control.Feedback>
              </Col>
              <Col xs={2} md="1" className="mt-2">
                <OverlayTrigger
                          key="denominazioneAziendaSearchTooltip"
                          placement="right"
                          overlay={
                            <Tooltip id={`denominazioneAziendaSearchTooltip-right`}>
                              {t('formScreen.companySearchTooltipMessage')}
                            </Tooltip>
                          }
                        >
                <Button
                  type="button"
                  variant="primary"
                  className="btn btn-primary"
                  placeholder={t("formScreen.validationMessages.PlaceH1")}
                  readOnly={showConfirmFormPreview}
                  value="Cerca"
                  name="searchCodiceFiscaleDatoreDiLavoro"
                  onClick={() =>
                    fnSearchForDenominazioneAzienda(
                      values.codiceFiscaleDatoreDiLavoro
                    )
                  }
                  disabled={
                    showConfirmFormPreview ||
                    !touched.codiceFiscaleDatoreDiLavoro ||
                    (errors.codiceFiscaleDatoreDiLavoro &&
                      touched.codiceFiscaleDatoreDiLavoro)
                  }
                  data-id-test={
                    "searchCodiceFiscaleDatoreDiLavoro-" + dataIdTestFormControl
                  }
                >
                    {
                        loadingDatore.is ?

                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : 
                        
                          <i className="bi bi-search" />
                        
                    }
                </Button>
                </OverlayTrigger>
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-searchDenominazioneDatoreDiLavoro"
                >
                  {errors.searchDenominazioneDatoreDiLavoro}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.Datore2")}
                </Form.Label>
              </Col>
              <Col md={9} className="mt-2">
                <Form.Control
                  required
                  type="text"
                  placeholder={t("formScreen.validationMessages.PlaceH3")}
                  readOnly={
                    showConfirmFormPreview || denominazioneAzienda !== ""
                  }
                  maxLength={100}
                  disabled={
                    showConfirmFormPreview || denominazioneAzienda !== ""
                  }
                  value={
                    (values.denominazioneDatoreDiLavoro =
                      denominazioneAzienda ||
                      values.denominazioneDatoreDiLavoro)
                  }
                  name="denominazioneDatoreDiLavoro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.denominazioneDatoreDiLavoro &&
                    touched.denominazioneDatoreDiLavoro
                  }
                  data-id-test={
                    "denominazioneDatoreDiLavoro-" + dataIdTestFormControl
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-denominazioneDatoreDiLavoro"
                >
                  {errors.denominazioneDatoreDiLavoro}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} className={styles.testataForm}>
                <Col className="Col">
                  {t("formScreen.validationMessages.title2")}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={styles.titolettoForm}>
                <Col className="Col">
                  {t("formScreen.validationMessages.title4")}
                </Col>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} md={3} className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.LavoratoreCF")}
                </Form.Label>
              </Col>
              <Col md={3} xs={10} className="mt-2">
                <Form.Control
                  required
                  type="text"
                  placeholder={t("formScreen.validationMessages.PlaceH2")}
                  readOnly={showConfirmFormPreview}
                  value={values.codiceFiscaleLavoratore}
                  maxLength={16}
                  name="codiceFiscaleLavoratore"
                  onChange={(e) => {
                    uppercase(e);
                    trim(e);
                    resetCFLavoratore();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.codiceFiscaleLavoratore &&
                    touched.codiceFiscaleLavoratore
                  }
                  data-id-test={
                    "codiceFiscaleLavoratore-" + dataIdTestFormControl
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-codiceFiscaleLavoratore"
                >
                  {errors.codiceFiscaleLavoratore}
                </Form.Control.Feedback>
              </Col>
              <Col md={1} xs={2} className="mt-2">
                <OverlayTrigger
                          key="denominazioneLavoratoreSearchTooltip"
                          placement="right"
                          overlay={
                            <Tooltip id={`denominazioneLavoratoreSearchTooltip-right`}>
                              {t('formScreen.employeeSearchTooltipMessage')}
                            </Tooltip>
                          }
                        >
                <Button
                  type="button"
                  variant="primary"
                  className="btn btn-primary"
                  readOnly={showConfirmFormPreview}
                  value="Cerca"
                  name="searchCodiceFiscaleLavoratore"
                  onClick={() =>
                    fnSearchForDenominazioneLavoratore(
                      values.codiceFiscaleLavoratore
                    )
                  }
                  disabled={
                    showConfirmFormPreview ||
                    !touched.codiceFiscaleLavoratore ||
                    (errors.codiceFiscaleLavoratore &&
                      touched.codiceFiscaleLavoratore)
                  }
                  data-id-test={
                    "searchCodiceFiscaleLavoratore-" + dataIdTestFormControl
                  }
                >
                  {
                        loadingLavoratore.is ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : 
                        <i className="bi bi-search" />
                    }
                </Button>
                </OverlayTrigger>
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-searchDenominazioneLavoratore"
                >
                  {errors.searchDenominazioneLavoratore}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.LavoratoreCognome")}
                </Form.Label>
              </Col>
              <Col md="3" className="mt-2">
                <Form.Control
                  required
                  type="text"
                  placeholder={t("formScreen.validationMessages.PlaceH4")}
                  readOnly={
                    showConfirmFormPreview ||
                    denominazioneLavoratoreCognome !== ""
                  }
                  disabled={
                    showConfirmFormPreview ||
                    denominazioneLavoratoreCognome !== ""
                  }
                  value={
                    (values.cognomeLavoratore =
                      denominazioneLavoratoreCognome ||
                      values.cognomeLavoratore)
                  }
                  name="cognomeLavoratore"
                  maxLength={50}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.cognomeLavoratore && touched.cognomeLavoratore
                  }
                  data-id-test={"cognomeLavoratore-" + dataIdTestFormControl}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-cognomeLavoratore"
                >
                  {errors.cognomeLavoratore}
                </Form.Control.Feedback>
              </Col>
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.LavoratoreNome")}
                </Form.Label>
              </Col>
              <Col md="3" className="mt-2">
                <Form.Control
                  required
                  type="text"
                  placeholder={t("formScreen.validationMessages.PlaceH5")}
                  readOnly={
                    showConfirmFormPreview || denominazioneLavoratoreNome !== ""
                  }
                  disabled={
                    showConfirmFormPreview || denominazioneLavoratoreNome !== ""
                  }
                  value={
                    (values.nomeLavoratore =
                      denominazioneLavoratoreNome || values.nomeLavoratore)
                  }
                  name="nomeLavoratore"
                  maxLength={50}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.nomeLavoratore && touched.nomeLavoratore}
                  data-id-test={"nomeLavoratore-" + dataIdTestFormControl}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-nomeLavoratore"
                >
                  {errors.nomeLavoratore}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className={styles.titolettoForm}>
                <Col className="Col">
                  {t("formScreen.validationMessages.title5")}
                </Col>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.Rapporto")}
                </Form.Label>
              </Col>
              <Col md="3" className="mt-2">
                <Form.Control
                  required
                  type="date"
                  placeholder="Data inizio"
                  readOnly={showConfirmFormPreview}
                  value={values.dataInizioRapportoDiLavoro}
                  name="dataInizioRapportoDiLavoro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.dataInizioRapportoDiLavoro &&
                    touched.dataInizioRapportoDiLavoro
                  }
                  data-id-test={
                    "dataInizioRapportoDiLavoro-" + dataIdTestFormControl
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-dataInizioRapportoDiLavoro"
                >
                  {errors.dataInizioRapportoDiLavoro}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className={styles.titolettoForm}>
                <Col className="Col">
                  {t("formScreen.validationMessages.title6")}
                </Col>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.DatiInvio1")}
                </Form.Label>
              </Col>
              <Col md="9" className="mt-2">
                <Form.Control
                  type="text"
                  required
                  placeholder={t("formScreen.validationMessages.PlaceH6")}
                  readOnly={showConfirmFormPreview}
                  value={values.motivoUrgenza}
                  name="motivoUrgenza"
                  maxLength={100}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.motivoUrgenza && touched.motivoUrgenza}
                  data-id-test={"motivoUrgenza-" + dataIdTestFormControl}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-motivoUrgenza"
                >
                  {errors.motivoUrgenza}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label>
                  <Form.Label className="Form">
                    {t("formScreen.validationMessages.DatiInvio2")}
                  </Form.Label>
                </Form.Label>
              </Col>
              <Col md="9" className="mt-2">
                <Form.Select
                  aria-label="Soggetto che effettua la comunicazione"
                  placeholder="Soggetto che effettua la comunicazione"
                  disabled={showConfirmFormPreview}
                  value={values.soggettoCheEffettuaLaComunicazione}
                  name="soggettoCheEffettuaLaComunicazione"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.soggettoCheEffettuaLaComunicazione &&
                    touched.soggettoCheEffettuaLaComunicazione
                  }
                  data-id-test={
                    "soggettoCheEffettuaLaComunicazione-" +
                    dataIdTestFormControl
                  }
                >
                  {isSubjectLoaded ? 
                    communicationSubjects.map((s) => {
                      return (
                        <option className="Form" id={s.id} value={s.value}>
                          {t(s.text)}
                        </option>
                      );
                    }) : 
                     <option value=''></option>
                  }
                </Form.Select>

                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-soggettoCheEffettuaLaComunicazione"
                >
                  {errors.soggettoCheEffettuaLaComunicazione}
                </Form.Control.Feedback>
              </Col>
            </Row>
            {values.soggettoCheEffettuaLaComunicazione !== "" &&
            values.soggettoCheEffettuaLaComunicazione !== "000" ? (
              <Row className="mb-2">
                <Col md="3" className={styles.labelForm + " mt-2"}>
                  <Form.Label>
                    Codice fiscale del soggetto che effettua la comunicazione *
                  </Form.Label>
                </Col>
                <Col md="3" className="mt-2">
                  <Form.Control
                    // required
                    type="text"
                    placeholder={t("formScreen.validationMessages.PlaceH9")}
                    readOnly={showConfirmFormPreview}
                    value={
                      values.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione
                    }
                    name="codiceFiscaleDelSoggettoCheEffettuaLaComunicazione"
                    maxLength={16}
                    onChange={(e) => {
                      uppercase(e);
                      trim(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isValid={
                      values.soggettoCheEffettuaLaComunicazione === ""
                        ? true
                        : false
                    }
                    isInvalid={
                      errors.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione &&
                      touched.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione
                    }
                    data-id-test={
                      "codiceFiscaleDelSoggettoCheEffettuaLaComunicazione-" +
                      dataIdTestFormControl
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    data-test-id="invalid-codiceFiscaleDelSoggettoCheEffettuaLaComunicazione"
                  >
                    {errors.codiceFiscaleDelSoggettoCheEffettuaLaComunicazione}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row className="mb-2">
              <Col md="3" className={styles.labelForm + " mt-2"}>
                <Form.Label className="Form">
                  {t("formScreen.validationMessages.DatiInvio3")}
                </Form.Label>
              </Col>
              <Col md="" className="mt-2">
                <Form.Control
                  // required
                  type="email"
                  placeholder={t("formScreen.validationMessages.PlaceH7")}
                  readOnly={showConfirmFormPreview}
                  value={values.emailDelSoggettoCheEffettuaLaComunicazione}
                  name="emailDelSoggettoCheEffettuaLaComunicazione"
                  maxLength={80}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    errors.emailDelSoggettoCheEffettuaLaComunicazione &&
                    touched.emailDelSoggettoCheEffettuaLaComunicazione
                  }
                  data-id-test={
                    "emailDelSoggettoCheEffettuaLaComunicazione-" +
                    dataIdTestFormControl
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-test-id="invalid-emailDelSoggettoCheEffettuaLaComunicazione"
                >
                  {errors.emailDelSoggettoCheEffettuaLaComunicazione}
                </Form.Control.Feedback>
              </Col>
            </Row>
            {!showConfirmFormPreview ? (
              <Row className="mt-4">
                <Col xs="12" style={{ padding: "0px" }}>
                  <Button
                    className={styles.buttonSubmitForm}
                    type="submit"
                    disabled={loading.is}
                  >
                    {t("formScreen.validationMessages.bt")}
                    {loading.is ? <Loading size="sm" /> : ""}
                  </Button>
                </Col>
              </Row>
            ) : (
              <>
              <Row className="mt-4">
                <Col xs="12">
                  <Col className="Col">
                    {t("formScreen.validationMessages.TextAttention")}
                  </Col>
                </Col>
              </Row>
              {error.is ? <Alert variant="danger">{error.text}</Alert> : ""}
              <Row>
                <Col sm="6" className="d-grid">
                  <Button variant="secondary" onClick={handleModify}>
                    {t("formScreen.validationMessages.btnModify")}
                  </Button>
                </Col>
                <Col sm="6" className="d-grid">
                  <Button
                    onClick={() => functionApiCreateNewComunication(false)}
                    className={styles.buttonSubmitForm}
                    type="submit"
                    disabled={loading.is}
                  >
                    {t("formScreen.validationMessages.ButtonSave2")}
                  </Button>
                </Col>
              </Row>
              </>
            )}
          </Form>
        )}
      </Formik>
      </>
    </Container>
  );
}

const Loader = () => (
  <div className="FormScreen">
    <div>loading...</div>
  </div>
);
//export default FormScreen;
export default function FormScreen() {
  return (
    <Suspense fallback={<Loader />}>
      <FFormScreen />
    </Suspense>
  );
}
