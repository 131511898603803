import { Nav, Image, Navbar, Container } from "react-bootstrap";
import { linkNav } from "../common/data";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Footer.css";
import { AuthConsumer } from "../../AuthContext";

function Footer() {
  const { t } = useTranslation();
  const { authenticated } = AuthConsumer();

  return (
    <div className="containerFooter">
      <Container fluid>
        <Navbar className="footerNavbar">
          <Container fluid>
            <Navbar.Brand className="hideOnMobile">
            <a
                key={"footer-logo-url"}
                className=""
                target="_blank"
                rel="noreferrer"
                href={t('footer.logoUrl')}
            >
              <Image 
                src="/ML-Horizontal-White.svg"
                width="100%"
                alt="Logo Ministero del Lavoro e delle Politiche Sociali"
              />
            </a>
            </Navbar.Brand>
            <Nav className="justify-content-end">
            {linkNav.map((el) => {
              if (el.linkEsterno === true && el.isActive === true) {
                return (
                  <Nav.Item key={"nav-item-" + el.id.toString()}>
                    <a
                        key={"ex-nav-" + el.id.toString()}
                        className="linkNav nav-link"
                        target="_blank"
                        rel="noreferrer"
                        href={t(el.url)}
                      >
                        {t(el.nomePagina)}
                      </a>
                  </Nav.Item>
                );
              } else {
                return  (
                  el.isProtected === true && el.isActive === true && authenticated === true ?
                  <Nav.Item key={"nav-" + el.id.toString()} fill>
                    <Link
                      key={el.id.toString()}
                      to={el.url}
                      className="linkNav nav-link"
                    >
                      {t(el.nomePagina)}
                    </Link>
                  </Nav.Item>
                 : 
                  ""
                );
              }
            })}
              <Nav.Item key="nav-item-privacy">
                <Link 
                  key="link-privacy" 
                  className="linkNav nav-link" 
                  to="/privacy"
                >
                  {t('footer.legal')}
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
}

export default Footer;
