import axios from "axios";

export async function callApiGetCommunications(
    email, 
    codiceFiscaleDatore,
    codiceFiscaleLavoratore, 
    fromDate, 
    toDate,
    pageSize,
    offset) {
    var dataInizio;
    var dataFine;
    const urlCallBase = process.env.NODE_ENV === 'production' ? window.env.API_URL : process.env.REACT_APP_API_URL;
    let urlCall = urlCallBase + "/communications";

    // PageSize + Offset
    urlCall += urlCall.includes("?") ? "&pageSize=" + pageSize + "&offset=" + offset : "?pageSize=" + pageSize + "&offset=" + offset;

    if (codiceFiscaleDatore!=="") {
        if(urlCall.includes("?")) {
            urlCall = urlCall + "&codiceFiscaleDatore=" + codiceFiscaleDatore;
        } else {
            urlCall = urlCall + "?codiceFiscaleDatore=" + codiceFiscaleDatore;
        }
    }

    if (codiceFiscaleLavoratore!=="") {
        if(urlCall.includes("?")) {
            urlCall = urlCall + "&codiceFiscaleLavoratore=" + codiceFiscaleLavoratore;
        } else {
            urlCall = urlCall + "?codiceFiscaleLavoratore=" + codiceFiscaleLavoratore;
        }
    }


    if (fromDate!=="") {
        let date = new Date(fromDate);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        dataInizio = yyyy + "-" + mm + "-" + dd;

        if(urlCall.includes("?")) {
            urlCall = urlCall + "&startDate=" + dataInizio;
        } else {
            urlCall = urlCall + "?startDate=" + dataInizio;
        }
    }

    if (toDate!=="") {
        let date = new Date(toDate);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();

        dataFine = yyyy + "-" + mm + "-" + dd;

        if(urlCall.includes("?")) {
            urlCall = urlCall + "&endDate=" + dataFine;
        } else {
            urlCall = urlCall + "?endDate=" + dataFine;
        }
    }

    return await axios({
        method: "get",
        url: urlCall,
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error;
    });
}

export async function callApiGetAllCommunications(
    codiceFiscaleDatore,
    codiceFiscaleLavoratore,
    fromDate, 
    toDate,
    coapStatus,
    pageSize,
    offset) {
    var dataInizio;
    var dataFine;
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var urlCall = urlCallBase + "/administration";

    // PageSize + Offset
    urlCall += urlCall.includes("?") ? "&pageSize=" + pageSize + "&offset=" + offset : "?pageSize=" + pageSize + "&offset=" + offset;

    if (codiceFiscaleDatore!=="") {
        if(urlCall.includes("?")) {
            urlCall = urlCall + "&codiceFiscaleDatore=" + codiceFiscaleDatore;
        } else {
            urlCall = urlCall + "?codiceFiscaleDatore=" + codiceFiscaleDatore;
        }
    }

    if (codiceFiscaleLavoratore!=="") {
        if(urlCall.includes("?")) {
            urlCall = urlCall + "&codiceFiscaleLavoratore=" + codiceFiscaleLavoratore;
        } else {
            urlCall = urlCall + "?codiceFiscaleLavoratore=" + codiceFiscaleLavoratore;
        }
    }

    if (fromDate!=="") {
        let date = new Date(fromDate);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        dataInizio = yyyy + "-" + mm + "-" + dd;

        if(urlCall.includes("?")) {
            urlCall = urlCall + "&startDate=" + dataInizio;
        } else {
            urlCall = urlCall + "?startDate=" + dataInizio;
        }
    }

    if (toDate!=="") {
        let date = new Date(toDate);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = date.getFullYear();

        dataFine = yyyy + "-" + mm + "-" + dd;

        if(urlCall.includes("?")) {
            urlCall = urlCall + "&endDate=" + dataFine;
        } else {
            urlCall = urlCall + "?endDate=" + dataFine;
        }
    }

    if(coapStatus!==""){
        if(urlCall.includes("?")) {
            urlCall = urlCall + "&coapStatus=" + coapStatus;
        } else {
            urlCall = urlCall + "?coapStatus=" + coapStatus;
        }
    }

    return await axios({
        method: "get",
        url: urlCall,
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error;
    });
}

export async function me() {
    const urlBase = process.env.NODE_ENV === "production" ? window.env.SPID_API_URL : process.env.REACT_APP_SPID_API_URL;
    var url = urlBase + "/me";

    return await axios({
        method: "get",
        url: url,
        withCredentials: true,        
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error.response;
    });
}

export async function isAdmin() {
    const urlBase = process.env.NODE_ENV === "production" ? window.env.SPID_API_URL : process.env.REACT_APP_SPID_API_URL;
    var url = urlBase + "/role";

    return await axios({
        method: "get",
        url: url,
        withCredentials: true,        
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error;
    });
}

export async function logout() {
    const urlBase = process.env.NODE_ENV === "production" ? window.env.SPID_API_URL : process.env.REACT_APP_SPID_API_URL;
    var url = urlBase + "/logout";

    return await axios({
        method: "get",
        url: url,
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error.response;
    });
}

export async function cieSso() {
    
    const url = process.env.NODE_ENV === 'production' ? window.env.CIE_REQUEST_ID_URL : process.env.REACT_APP_CIE_REQUEST_ID_URL;

    return axios({
        method: "get",
        url: url,
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error.response;
    });
}

export async function resendHD(id) {
    const urlBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var url = urlBase + "/administration/" + id + "/resend";

    return await axios({
        method: "post",
        url: url,
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error.response;
    });
}

export async function getCommunicationSubjects() {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var urlCall = urlCallBase + "/subjects";

    return await axios({
            method: "get",
            url: urlCall,
            withCredentials: true,
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });
}

export async function getDenominazioneAzienda(codiceFiscalePartitaIva) {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_REGISTRY_URL : process.env.REACT_APP_API_REGISTRY_URL;
    var urlCall = urlCallBase + "/denominazioneAzienda?code=" + codiceFiscalePartitaIva;

    return await axios({
            method: "get",
            url: urlCall,
            withCredentials: true,
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
}

export async function getDenominazioneLavoratore(codiceFiscale) {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_REGISTRY_URL : process.env.REACT_APP_API_REGISTRY_URL;
    var urlCall = urlCallBase + "/denominazioneLavoratore?code=" + codiceFiscale;

    return await axios({
            method: "get",
            url: urlCall,
            withCredentials: true,
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error.response;
        });
}

export async function callApiGetComunicationById(id) {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var urlCall = urlCallBase + "/communications/" + id;

    return await axios({
            method: "get",
            url: urlCall,
            withCredentials: true
        })
        .then(response => {
            return response;
        });
}

export async function callApiGetComunicationCoapById(id) {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var urlCall = urlCallBase + "/communications/" + id + "/coap";

    return await axios({
            method: "get",
            url: urlCall,
            withCredentials: true,
        })
        .then(response => {
            return response;
        });
}

export async function callApiCreateNewComunication(jsonData) {
    const urlCallBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    var urlCall = urlCallBase + "/communications";

    return await axios({
            method: "post",
            url: urlCall,
            data: jsonData,
            headers: { 
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            mode: "no-cors",
            withCredentials: true,
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });
}

export async function callApiGetGeneratePDF(id) {
    
    const urlBase = process.env.NODE_ENV === "production" ? window.env.API_URL : process.env.REACT_APP_API_URL;
    const url = urlBase + "/communications/" + id + "/pdf";
    
    return await axios({
        method: "get",
        url: url,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        withCredentials: true,
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return error.response;
    });
}
