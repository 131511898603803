import { Col, Container, Row, Alert } from "react-bootstrap";

function NotFoundScreen() {
    return (
        <Container className="containerBody" fluid>
            <Row>
                <Col xs="12" className="mb-3">
                    <Alert variant="warning">
                        ATTENZIONE: Questa pagina sembra non esistere!
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
  }
  
  export default NotFoundScreen;
  