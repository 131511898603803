import {Spinner} from "react-bootstrap";

function Loading({size=""}) {

    return (
        <Spinner
            as="span"
            animation="border"
            size={size}
            role="status"
            aria-hidden="true"
        />
    );
  }
  
  export default Loading;
  