import HomeScreen from "../Body/HomeScreen/HomeScreen";
import MyCommunicationsScreen from "../Body/MyCommunicationsScreen/MyCommunicationsScreen";

const faqsFeatureEnabled = process.env.NODE_ENV === 'production' ? window.env.FAQS_FEATURE_ENABLED : process.env.REACT_APP_FAQS_FEATURE_ENABLED;
const manualFeatureEnabled = process.env.NODE_ENV === 'production' ? window.env.MANUAL_FEATURE_ENABLED : process.env.REACT_APP_MANUAL_FEATURE_ENABLED;
const contactsFeatureEnabled = process.env.NODE_ENV === 'production' ? window.env.CONTACTS_FEATURE_ENABLED : process.env.REACT_APP_CONTACTS_FEATURE_ENABLED;

export const linkNav = [
    {
      id: 1,
      url: "/home",
      linkEsterno: false,
      isProtected: true,
      nomePagina: "menu.home",
      screen: HomeScreen,
      isActive: true
    },
    {
      id: 2,
      url: "/mycommunications",
      linkEsterno: false,
      isProtected: true,
      nomePagina: "menu.mycommunications",
      screen: MyCommunicationsScreen,
      isActive: true
    },
    {
      id: 3,
      url: "menu.manualsUrl",
      linkEsterno: true,
      isProtected: false,
      nomePagina: "menu.manuals",
      isActive: manualFeatureEnabled === 'true' ?? false
    },
    {
      id: 4,
      url: "menu.faqsUrl",
      linkEsterno: true,
      isProtected: false,
      nomePagina: "menu.faqs",
      isActive: faqsFeatureEnabled === 'true' ?? false
    },
    {
      id: 5,
      url: "menu.contactsUrl",
      linkEsterno: true,
      isProtected: false,
      nomePagina: "menu.contacts",
      isActive: contactsFeatureEnabled === 'true' ?? false
    },
  ];