import { useState } from "react";
import {
  Col,
  Row,
  Button,
  OverlayTrigger,
  Popover,
  Container,
} from "react-bootstrap";
import "./UserLogged.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthConsumer } from "../../../AuthContext";
import { logout } from "../callApi";
import {
  BrowserView,
  MobileView
} from "react-device-detect";

function UserLogged() {
  const [showOverlay, setShowOverlay] = useState(false);
  const { authenticated, user, signout } = AuthConsumer();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loggingout = () => {
    setShowOverlay(false);
    logout();
    signout();
    navigate("/");
  };

  return (
    <div>
      <BrowserView>
        { authenticated === true && user !== null ? (
          <OverlayTrigger
            trigger="click"
            key="userLoggedTrigger"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body>
                  <div className="">
                    <Row style={{}} className="p-1 m-1">
                      <Col xs="auto" style={{ fontSize: "30px" }}>
                        <i className="bi bi-person-circle"></i>
                      </Col>
                      <Col xs="auto">
                        <p style={{ margin: "0px" }}>{user?.fiscalNumber}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" className="d-grid">
                        <Button onClick={loggingout}>{t("logout")}</Button>
                      </Col>
                    </Row>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <Container>
              <Row
                className="p-1 m-1 user-logged-item"
                onClick={() => setShowOverlay(!showOverlay)}
              >
                <Col
                  xs="auto"
                  style={{ fontSize: "30px" }}
                  className="hideOnMobile"
                >
                  <i className="bi bi-person-circle"></i>
                </Col>
                <Col>
                  <span className="text-capitalize">{t("user.welcome")}</span>
                  <p className="fw-bold" style={{ margin: "0px" }}>
                    {user?.fiscalNumber}
                  </p>
                </Col>
              </Row>
            </Container>
          </OverlayTrigger>
        ) : (
          ""
        )}
      </BrowserView>
      <MobileView>
        { authenticated === true && user !== null ? (
          <Container>
            <Row className="p-1 m-1 user-logged-item">
              <Col>
                <span className="text-capitalize">{t("user.welcome")}</span>
                <p className="fw-bold" style={{ margin: "0px" }}>
                  {user?.fiscalNumber}
                </p>
              </Col>
            </Row>
            <Row className="p-1 m-1 user-logged-item">
                <Col className="d-grid">
                  <Button onClick={loggingout}>{t("logout")}</Button>
                </Col>
              </Row>
          </Container>
        ) : (
          ""
        )}
      </MobileView>
    </div>
  );
}

export default UserLogged;
