import { Col, Container, Row } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

function LegalAndPrivacyScreen() {
  const { t } = useTranslation();
  return (
    <Container className="containerBody" fluid>
      <Row>
        <Col xs="12">
          <Col className="Col">
            <Trans i18nKey="privacy.text" components={[
              {/* <a className="simple-link" target="_blank" href={t('privacy.linkToGdprPec')} rel="noreferrer">  </a>,
              <a className="simple-link" target="_blank" href={t('privacy.linkToGdprMail')} rel="noreferrer"> </a>,
              <a className="simple-link" target="_blank" href={t('privacy.linkToDPO')} rel="noreferrer">  </a>,
              <a className="simple-link" target="_blank" href={t('privacy.linkToSite')} rel="noreferrer">  </a> */}
            ]}>
            </Trans>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default LegalAndPrivacyScreen;
