import "./LoginSPIDButton.css";

function LoginSPIDButton({sizeButton= "small"}) {
    const spidRequestIdUrl = process.env.NODE_ENV === 'production' ? window.env.SPID_REQUEST_ID_URL : process.env.REACT_APP_SPID_REQUEST_ID_URL;
    const spidRequestBaseUrl = process.env.NODE_ENV === 'production' ? window.env.SPID_REQUEST_BASE_URL : process.env.REACT_APP_SPID_REQUEST_BASE_URL;

    class providerItem {
        constructor(pId, pUrl, pText, pSvg, pPng, pAlt) {
            this.Id = pId;
            this.Url = pUrl;
            this.Text = pText;
            this.Svg = pSvg;
            this.Png = pPng;
            this.Alt = pAlt;
        }
    }

    const providersList = new Array(
        new providerItem("arubaid", spidRequestBaseUrl + "aruba" + spidRequestIdUrl, "Aruba ID", "/img/spid-idp-arubaid.svg", 'img/spid-idp-arubaid.png', "Aruba ID"),
        new providerItem("infocertid", spidRequestBaseUrl + "infocert" + spidRequestIdUrl, "Infocert ID", "/img/spid-idp-infocertid.svg", 'img/spid-idp-infocertid.png', "Infocert ID"),
        new providerItem("lepidaid", spidRequestBaseUrl + "lepida" + spidRequestIdUrl, "Lepida ID", "/img/spid-idp-lepidaid.svg", 'img/spid-idp-lepidaid.png', "Lepida ID"),
        new providerItem("namirialid", spidRequestBaseUrl + "namirial" + spidRequestIdUrl, "Namirial ID", "/img/spid-idp-namirialid.svg", 'img/spid-idp-namirialid.png', "Namirial ID"),
        new providerItem("posteid", spidRequestBaseUrl + "poste" + spidRequestIdUrl, "Poste ID", "/img/spid-idp-posteid.svg", 'img/spid-idp-posteid.png', "Poste ID"),
        new providerItem("sielteid", spidRequestBaseUrl + "sielte" + spidRequestIdUrl, "Sielte ID", "/img/spid-idp-sielteid.svg", 'img/spid-idp-sielteid.png', "Sielte ID"),
        new providerItem("spiditalia", spidRequestBaseUrl + "register" + spidRequestIdUrl, "SPIDItalia Register.it", "/img/spid-idp-spiditalia.svg", 'img/spid-idp-spiditalia.png', "SPIDItalia"),
        new providerItem("timid", spidRequestBaseUrl + "tim" + spidRequestIdUrl, "Tim ID", "/img/spid-idp-timid.svg", 'img/spid-idp-timid.png', "Tim ID"),
        new providerItem("teamsystemid", spidRequestBaseUrl + "teamsystem" + spidRequestIdUrl, "TeamSystem ID", "/img/spid-idp-teamsystemid.svg", 'img/spid-idp-teamsystemid.png', "TeamSystem ID"),
        new providerItem("intesigroup", spidRequestBaseUrl + "intesigroup" + spidRequestIdUrl, "Intesigroup ID", "/img/spid-idp-intesigroupspid.svg", 'img/spid-idp-intesigroupspid.png', "Intesigroup ID"),
        new providerItem("etnaid", spidRequestBaseUrl + "etnaid" + spidRequestIdUrl, "Etnaid ID", "/img/spid-idp-etnaid.svg", 'img/spid-idp-etnaid.png', "EtnaID ID"),
        new providerItem("infocamere", spidRequestBaseUrl + "infocamere" + spidRequestIdUrl, "infocamere", "/img/spid-idp-infocamereid.svg", 'img/spid-idp-infocamereid.png', "infocamere")    
    );

    const buildProvidersDropDownList = () => {
        return providersList.sort(() => Math.random() - 0.5).map(x => 
            <li class="spid-idp-button-link" data-idp={x.Id} key={x.id}>
                <a href={x.Url} key={x.id}>
                    <span className="spid-sr-only" key={x.id}>{x.Text}</span>
                    <img src={x.Svg} onerror="this.src=' + {x.Svg} + '; this.onerror=null;" alt={x.Alt} key={x.id}/>
                </a>
            </li>
        );
    }

    return (
        <div className="container-fluid">
            <a href="#" className={"italia-it-button italia-it-button-size-" + sizeButton.charAt(0) + " button-spid"} spid-idp-button="#spid-idp-button-small-get" aria-haspopup="true" aria-expanded="false">
                <span className="italia-it-button-icon"><img src="/img/spid-ico-circle-bb.svg" onerror="this.src='/img/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
                <span className="italia-it-button-text">Entra con SPID</span>
            </a>
            <div id={"spid-idp-button-"+ sizeButton + "-get"} className="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
                <ul id="spid-idp-list-small-root-get" className="spid-idp-button-menu" aria-labelledby="spid-idp">                    
                    {buildProvidersDropDownList()}
                    <li className="spid-idp-support-link">
                        <a href="https://www.spid.gov.it">Maggiori informazioni</a>
                    </li>                    
                    <li className="spid-idp-support-link">
                        <a href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a>
                    </li>
                    <li className="spid-idp-support-link">
                        <a href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a>
                    </li>                    
                </ul>
            </div>
       </div> 
    );
  }

  export default LoginSPIDButton;
  