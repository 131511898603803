import React, { Suspense } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet
} from "react-router-dom";
import CieErrorScreen from "./components/Body/ErrorScreen/CieErrorScreen";
import NotFoundScreen from "./components/Body/ErrorScreen/NotFoundScreen";
import HomeScreen from "./components/Body/HomeScreen/HomeScreen";
import FormScreen from "./components/Body/FormScreen/FormScreen";
import DetailScreen from "./components/Body/DetailScreen/DetailScreen";
import MyCommunicationsScreen from "./components/Body/MyCommunicationsScreen/MyCommunicationsScreen";
import CommunicationsScreen from "./components/Body/MyCommunicationsScreen/CommunicationsScreen";
import LandingPageScreen from "./components/Body/LandingPageScreen/LandingPageScreen";
import LegalAndPrivacyScreen from "./components/Body/LegalAndPrivacyScreen/LegalAndPrivacyScreen";
import CookieConsent from "react-cookie-consent";
import { AuthConsumer, AuthProvider } from "./AuthContext";
import { useTranslation, Trans } from "react-i18next";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'

function App() {
  const { t } = useTranslation();
  const { authenticated, admin } = AuthConsumer();

  function RequireAuth({ children }) {
    let location = useLocation();
  
    if (!authenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" state={{ from: location }} />;
    }
  
    return children;
  }

  function RequireAdminAuth({ children }) {
    let location = useLocation();
  
    if (!authenticated || !admin) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" state={{ from: location }} />;
    }
  
    return children;
  }

  const cookieConsentRef = React.useRef();

  return (
    <div className="MyApp">
      <CookieConsent
        ref={cookieConsentRef}
        location="bottom"
        buttonId="btn-consent-id"
        buttonWrapperClasses="btn-consent"
        buttonClasses="text-uppercase"
        buttonText={t("cookie.accept")}
        style={{ backgroundColor: "#30373D" }}
        overlay
        enableDeclineButton
        onDecline={() => cookieConsentRef.current.accept()}
        declineButtonId="btnDeclineCookieConsent"
        declineButtonClasses="text-uppercase"
        declineButtonText={t("cookie.refuse")}
        flipButtons        
      >
        <div class="top" style={{ textAlign: "right"}}>
            <a onClick={() => cookieConsentRef.current.accept()} id="btn-close-cookie" title={t("cookie.close")} style={{ fontSize: "1.5em", color: "#00ffff", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </a>
        </div>
        <h2>{t("cookie.title")}</h2>
        <Trans>
          <p>{t("cookie.description")}</p>
        </Trans>
        <p>
          <Trans i18nKey="cookie.moreinfo" components={[
              <a style={{ color: "#00ffff" }} target="_blank" href={t('gdpr.linkToPP')} rel="noreferrer">  </a>
            ]}>
            </Trans>
        </p>
      </CookieConsent>
      <AuthProvider>
        <Router>
          <Header />
          <Navbar />
          <ScrollToTop />          
          <Routes>
            <Route path="/" element={<LandingPageScreen />} />
            <Route path="/cie-error/:id" element={<CieErrorScreen />} />
            <Route path="/home" element={
              <RequireAuth>
                <HomeScreen />
              </RequireAuth>
              } />
            <Route path="/privacy" element={<LegalAndPrivacyScreen />} />
            <Route path="/new" element={
              <RequireAuth>
                <FormScreen />
              </RequireAuth>
              } />
            <Route
              path="/mycommunications"
              element={
                <RequireAuth>
                  <MyCommunicationsScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/communications"
              element={
                <RequireAdminAuth>
                  <CommunicationsScreen />
                </RequireAdminAuth>
              }
            />
            <Route
              path="/mycommunications/:id"
              exact
              element={
                <RequireAuth>
                  <DetailScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/mycommunications/:id/preview"
              exact
              element={
                <RequireAuth>
                  <DetailScreen />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
          <Outlet />
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

const Loader = () => (
  <div className="AppLoader">
    <div>loading...</div>
  </div>
);

export default function WrappedApp() {
  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  );
}
