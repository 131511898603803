import { usePagination, DOTS } from '../../../hooks/usePagination';
import { Pagination } from 'react-bootstrap';
import { BrowserView } from "react-device-detect";

const CustomPagination = props => {
  const {
    onPageChange,
    totalCount = 0,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    
    <Pagination className="cuu-pagination">
        <BrowserView>
          <Pagination.First disabled={currentPage === 1} onClick={() => onPageChange(1)} />
        </BrowserView>
        <Pagination.Prev disabled={currentPage === 1} onClick={() => onPrevious()} />
        {
            paginationRange.map(pageNumber => {
                if(pageNumber === DOTS) {
                    return <Pagination.Ellipsis />
                }

                return (
                    <Pagination.Item active={pageNumber === currentPage} onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                    </Pagination.Item>
                )
            })
        }
    
        <Pagination.Next disabled={currentPage === lastPage} onClick={() => onNext()} />
        <BrowserView>
          <Pagination.Last disabled={currentPage === lastPage} onClick={() => onPageChange(lastPage)} />
        </BrowserView>
    </Pagination>
    
  );
};

export default CustomPagination;