import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

function HomeScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container className="containerBody" fluid>
      <Row>
        <Col xs="12">
          <div className="d-grid mb-4">
            <Button
              variant="primary"
              onClick={() => navigate("/new")}
            >
              {t("homeScreen.sendNewCommunication")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Trans>
            {t("homeScreen.text")}
          </Trans>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeScreen;
