import { Col, Container, Row, Button } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

function GDPRPage({ setAcceptGDPR }) {
  const { t } = useTranslation();

  return (
    <Container className="containerBody" fluid>
      <Row>
        <Col xs="12" className="mb-3">
          <h2>{t("gdpr.title")}</h2>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Trans>
            {t("gdpr.text")}
          </Trans>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="d-grid mt-4">
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setAcceptGDPR(true)}
          >
            {t("gdpr.accept")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Trans>
            {t("gdpr.reference")}
          </Trans>
        </Col>
      </Row>
    </Container>
  );
}

export default GDPRPage;
