import React, {useState} from 'react';
import {
  Container,
  Image,
  Nav,
  Offcanvas,
  Navbar as NavBar
} from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { linkNav } from "../common/data";
import LoginSPIDButton from "../common/LoginSPIDButton/LoginSPIDButton";
import LoginCIEButton from "../common/LoginCIEButton/LoginCIEButton";
import UserLogged from "../common/UserLogged/UserLogged";
import { useTranslation } from "react-i18next";
import styles from "./Navbar.module.css";
import { AuthConsumer } from "../../AuthContext";

function Navbar() {
  const location = useLocation();
  const { t } = useTranslation();
  const { authenticated, admin } = AuthConsumer();
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);
  const handleClose = () => setShow(false);
  const cieFeatureEnabled = process.env.NODE_ENV === 'production' ? window.env.CIE_FEATURE_ENABLED === 'true' : process.env.REACT_APP_CIE_FEATURE_ENABLED === 'true';

  return (
    <div className="containerNavbar">
      <NavBar expand="md" variant="dark">
        <Container fluid>
        <NavBar.Toggle aria-controls={`offcanvasNavbar-expand-${show}`} label="'Toggle navigation'" onClick={toggleShow}  />
        <NavBar.Brand className="navbarBrand" href="/">
          <Image 
            fluid
            className="hideOnMobile"
            src="/img/couniurg_logo_nav_neg.svg"
            width="250px"
            alt="Logo Ministero"
          />{" "}
        </NavBar.Brand>
        
        <Nav className="me-auto justify-content-end flex-grow-1 pe-3 hideOnMobile">
              {linkNav.map((el) => {
                if (el.linkEsterno === true && el.isActive === true) {
                  return (
                    <Nav.Item
                      key={el.id.toString()}
                      className="ps-2 pe-2 pb-2 pt-2"
                    >
                      <a
                        key={el.id}
                        id={"nav-link-" + el.id}
                        className={styles.noActive + " nav-link navLink"}
                        target="_blank"
                        rel="noreferrer"
                        href={t(el.url)}
                      >
                        {t(el.nomePagina)}
                      </a>
                    </Nav.Item>
                  );
                } else {
                  return (
                    el.isProtected === true && authenticated === true && el.isActive === true ?
                    <Nav.Item className="ps-2 pe-2 pb-2 pt-2">
                    <Link
                      key={el.id.toString()}
                      to={el.url}
                      id={"nav-link-" + el.id}
                      className={
                        location.pathname !== el.url
                          ? "nav-link " + styles.noActive
                          : "nav-link"
                      }
                    >
                      {t(el.nomePagina)}
                    </Link>
                    </Nav.Item>
                   : (
                    ""
                  ));
                }
              })}
              {
                authenticated === true && admin === true ?
                <Nav.Item className="ps-2 pe-2 pb-2 pt-2">
                  <Link
                    key="k-admin"
                    to="/communications"
                    id="nav-link-helpdesk-communications"
                    className={
                      location.pathname !== "/communications"
                        ? "nav-link " + styles.noActive
                        : "nav-link"
                    }
                  >
                    {t('navbar.search')}
                  </Link>
                </Nav.Item>
               : ""
              }
        </Nav>
        <NavBar.Offcanvas
          autoFocus="true"
          id={`offcanvasNavbar-expand-${show}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${show}`}
          placement="start"
          className={styles.offcanvas}
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton closeVariant="black">
            <Image
                fluid
                src="/img/couniurg_logo_nav_pos.svg"
                alt="Logo Ministero"
                className={styles.logoCOCanvas}
              />
              
          </Offcanvas.Header>
          <Offcanvas.Body>
            
            {
              authenticated ?
              <>
                <hr />
                <div className="user-logged-canvas">
                  <UserLogged />
                </div>
              </>
            : ""
            }
            <hr />
            
            <Nav className="me-auto justify-content-end flex-grow-1 pe-3">
              {linkNav.map((el) => {
                if (el.linkEsterno === true && el.isActive === true) {
                  return (
                    <Nav.Item
                      key={el.id.toString()}
                      className="ps-2 pe-2 pb-2 pt-2"
                    >
                      <a
                        key={el.id}
                        id={"nav-link-offcanvas" + el.id}
                        className={styles.noActive + " nav-link"}
                        target="_blank"
                        rel="noreferrer"
                        href={t(el.url)}
                      >
                        {t(el.nomePagina)}
                      </a>
                    </Nav.Item>
                  );
                } else {
                  return (
                    el.isProtected === true && authenticated === true && el.isActive === true ?
                    <Nav.Item className="ps-2 pe-2 pb-2 pt-2">
                    <Link
                      onClick={toggleShow}
                      key={el.id.toString()}
                      to={el.url}
                      id={"nav-link-offcanvas" + el.id}
                      className={
                        location.pathname !== el.url
                          ? "nav-link " + styles.noActive
                          : "nav-link"
                      }
                    >
                      {t(el.nomePagina)}
                    </Link>
                    </Nav.Item>
                   : (
                    ""
                  ));
                }
              })}
              {
                authenticated && admin ?
                <Nav.Item className="ps-2 pe-2 pb-2 pt-2">
                  <Link
                    onClick={toggleShow}
                    key="k-admin"
                    to="/communications"
                    id="nav-link-helpdesk-communications"
                    className={
                      location.pathname !== "/communications"
                        ? "nav-link " + styles.noActive
                        : "nav-link"
                    }
                  >
                    {t('navbar.search')}
                  </Link>
                </Nav.Item>
               : ""
              }
            </Nav>
            
          </Offcanvas.Body>
        </NavBar.Offcanvas>
        { 
          authenticated === true ? 
          <div className="justify-content-end user-logged hideOnMobile">
            <UserLogged />
          </div> : 
          <React.Fragment>
            <div>
              <div className="justify-content-end btn-spid" style={{paddingBottom: '0.5em'}}>
                <span>
                  <LoginSPIDButton />
                </span>
              </div>
              {
                cieFeatureEnabled === true ?
                <div className="justify-content-end btn-spid">
                  <span>
                    <LoginCIEButton />
                  </span>
                </div> : ""
              }
            </div>
          </React.Fragment>          
        }        
        </Container>
      </NavBar>
      
    </div>
  );
}

export default Navbar;
