import { useParams, Navigate } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import { useEffect } from "react";

function CieErrorScreen() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();
  const { setError } = useGlobalContext();

  useEffect(() => {
    setError({
        is: true,
        status: null,
        text: id,
      });
  }, []);

  return (
    <Navigate to="/" replace={true} />
  );
}

export default CieErrorScreen;
