import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import styles from "./DetailScreen.module.css";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import { useEffect, useState } from "react";
import {
  callApiGetComunicationById,
  callApiGetComunicationCoapById,
  callApiGetGeneratePDF,
} from "../../common/callApi";
import Loading from "../../common/Loading/Loading";
import { useTranslation } from "react-i18next";
import { AuthConsumer, AuthProvider } from "../../../AuthContext";

function DetailScreen() {
  const {
    loading,
    setLoading,
    error,
    setError,
    dettaglioSelezionato,
    convertDate,
  } = useGlobalContext();
  const { id } = useParams();
  const { t } = useTranslation();

  const [comunicationData, setComunicationData] = useState([]);
  const [coapData, setCoapData] = useState({});
  const {admin} = AuthConsumer();

  const clickDownloadPDF = () => {
    setError({
      is: false,
      status: null,
      text: "",
    });
    setLoading({
      is: true,
      text: t('detailScreen.downloadingPdf'),
    });
    setTimeout(() => {
      callApiGetGeneratePDF(comunicationData.correlationId).then((response) => {
        if (response === undefined) {
          setError({
            is: true,
            status: null,
            text: t('detailScreen.errorDownloadingPdf'),
          });
          setLoading({
            is: false,
            text: "",
          });
        } else if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Ricevuta - ".concat(comunicationData.codiceComunicazione).concat(".pdf")); //or any other extension
          document.body.appendChild(link);
          link.click();
          setError({
            is: false,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        }
      });
    }, 1000);
  };

  const getCommunication = () => {
    callApiGetComunicationById(id).then((response) => {
      if (response === undefined) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: null,
          text: t('detailScreen.loadingDetailFailed'),
        });
      } else if (response.status === 200) {
        setComunicationData(response.data);
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: false,
          status: null,
          text: "",
        });
      } else if (response.status === 404) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: response.status,
          text: t('detailScreen.communicationNotFound'),
        });
      } else {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: null,
          text: t('detailScreen.loadingDetailFailed'),
        });
      }
    });
  };

  const getCommunicationCoap = () => {
    callApiGetComunicationCoapById(id).then((response) => {
      if (response === undefined) {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: null,
          text: t('detailScreen.loadingDetailFailed'),
        });
      } else if (response.status === 200) {
        setCoapData(response.data);
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: false,
          status: null,
          text: "",
        });
      } else {
        setLoading({
          is: false,
          text: "",
        });
        setError({
          is: true,
          status: null,
          text: t('detailScreen.loadingDetailFailed'),
        });
      }
    });
  };

  useEffect(() => {
    setLoading({
      is: true,
      text: t('detailScreen.loading'),
    });
    setError({
      is: false,
      status: null,
      text: "",
    });
    setTimeout(() => {
      getCommunication();
      getCommunicationCoap();
    }, 1000);
  }, []);

  if (Object.keys(comunicationData).length > 0) {
    return (
      <>
        <Container className="containerBody" fluid>
          <Row>
            <Col xs="12" className={styles.testataForm}>
              {t("formScreen.validationMessages.title")}
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={styles.titolettoForm}>
              {t("formScreen.validationMessages.title3")}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="codiceFiscaleDatoreDiLavoro"
            >
              <p>Codice Fiscale</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.datoreLavoro.cfDatoreLavoro}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="denominazioneDatoreDiLavoro"
            >
              <p>Denominazione datore di lavoro</p>
            </Col>
            <Col md="9" className="mt-2">
              <p>{comunicationData.datoreLavoro.denominazione}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" className={styles.testataForm}>
              <h6>Altre informazioni</h6>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={styles.titolettoForm}>
              <h6>Sezione: Lavoratore</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3" className={styles.labelForm + " mt-2"} id="codiceFiscaleLavoratore">
              <p>Codice Fiscale</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.lavoratore.cfLavoratore}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3" className={styles.labelForm + " mt-2"} id="cognomeLavoratore">
              <p>Cognome</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.lavoratore.cognome}</p>
            </Col>
            <Col md="3" className={styles.labelForm + " mt-2"} id="nomeLavoratore">
              <p>Nome</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.lavoratore.nome}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={styles.titolettoForm}>
              <h6>Sezione: Inizio rapporto di lavoro</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="dataInizioRapportoDiLavoro"
            >
              <p>Data inizio</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{convertDate(comunicationData.dataInizioRapporto)}</p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={styles.titolettoForm}>
              <h6>Sezione: Dati di invio</h6>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3" className={styles.labelForm + " mt-2"} id="motivoUrgenza">
              <p>Motivo dell'urgenza</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.motivoUrgenza}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="soggettoCheEffettuaLaComunicazione"
            >
              <p>
                Soggetto che effettua la comunicazione
                <p className="labelFacoltativoForm">
                  (se diverso dal datore di lavoro)
                </p>
              </p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.tipoDelegatoEsteso ? comunicationData.tipoDelegatoEsteso : "Datore di Lavoro"}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="codiceFiscaleDelSoggettoCheEffettuaLaComunicazione"
            >
              <p>
                Codice fiscale del soggetto che effettua la comunicazione
                <p className="labelFacoltativoForm">
                  (se diverso dal datore di lavoro)
                </p>
              </p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.cfDelegato}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              md="3"
              className={styles.labelForm + " mt-2"}
              id="emailDelSoggettoCheEffettuaLaComunicazione"
            >
              <p>E-mail del soggetto che effettua la comunicazione</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.emailDelegato}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3" className={styles.labelForm + " mt-2"} id="tipoComunicazione">
              <p>Tipo comunicazione</p>
            </Col>
            <Col md="3" className="mt-2">
              <p>{comunicationData.tipoComunicazioneEstesa}</p>
            </Col>
          </Row>
          {
            admin ?
            <>
              <Row>
                <Col xs="12" className={styles.titolettoForm}>
                  <h6>Sezione: COAP/NCN</h6>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="3" className={styles.labelForm + " mt-2"} id="coapStatus">
                  <p>Esito</p>
                </Col>
                <Col md="3" className="mt-2">
                  <p>{t('coap.status'+coapData.status)}</p>
                </Col>
              </Row>
            </>
            : ""
          }
          <Row>
            <Col xs="12" className="d-grid mt-3" style={{ padding: "0px" }}>
              <Button onClick={clickDownloadPDF} disabled={loading.is}>
                {loading.is ? (
                  " "
                ) : (
                  <i className="bi bi-file-earmark-arrow-down"></i>
                )}
                {loading.is ? <Loading size="sm" /> : ""}
                {loading.is ? " " + loading.text : " Scarica PDF"}
              </Button>
              <br />
              {error.is ? (
                error.status === 404 ? (
                  <Alert variant="warning">{error.text}</Alert>
                ) : (
                  <Alert variant="danger">{error.text}</Alert>
                )
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return (
    <>
      <Container className="containerBody" fluid>
        <Row>
          <Col xs="12" className="d-grid">
            {loading.is ? (
              <center>
                <p>
                  <Loading size="sm" /> {loading.text}
                </p>
              </center>
            ) : error.is ? (
              error.status === 404 ? (
                <Alert variant="warning">{error.text}</Alert>
              ) : (
                <Alert variant="danger">{error.text}</Alert>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DetailScreen;
