import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(smooth) {
  const { pathname } = useLocation();

  useEffect(() => {
    if(smooth) {
      window.scrollTo(0,0);
    } 
    else{
      document.documentElement.scrollTop = 0;
    }
  }, [pathname]);

  return null;
}