import { Col, Container, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Header() {
    const { t } = useTranslation();

    return (
    <Container fluid>
        <Row className="containerHeader">
            <Col xs="auto" className="hideOnMobile">
            <a
                key={"header-logo-url"}
                className=""
                target="_blank"
                rel="noreferrer"
                href={t('menu.logoUrl')}
            >
                <Image src="/logoMinistero.png" alt="Logo Ministero" width="30px" />
            </a>
            </Col>
            <Col xs="auto">
                <h6>Ministero del Lavoro e delle Politiche Sociali</h6>
            </Col>
        </Row>
    </Container>
    );
}

export default Header;
