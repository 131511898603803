import { Card, Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import { callApiGetGeneratePDF, resendHD } from "../../../common/callApi";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

function CardCommunication({ dataComunication, isHelpdesk = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resend, setResend] = useState(false);

  const {
    loading,
    setLoading,
    setError,
    convertDate,
    convertTime,
    setDettaglioSelezionato,
  } = useGlobalContext();

  const clickVisualizzaDettaglio = () => {
    setDettaglioSelezionato(dataComunication);
    navigate("/mycommunications/" + dataComunication.correlationId);
  };

  const clickDownloadPDF = () => {
    setError({
      is: false,
      status: null,
      text: "",
    });
    setLoading({
      is: true,
      text: t("myCommunicationScreen.downloadingPdf"),
    });
    setTimeout(() => {
      callApiGetGeneratePDF(dataComunication.correlationId).then((response) => {
        if (response === undefined) {
          setError({
            is: true,
            status: null,
            text: t("errorDownloadingPdf"),
          });
          setLoading({
            is: false,
            text: "",
          });
        } else if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Ricevuta - "
              .concat(dataComunication.codiceComunicazione)
              .concat(".pdf")
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setError({
            is: false,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        } else {
          setError({
            is: true,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        }
      });
    }, 1000);
  };

  const fnResendHD = (id) => {
    setError({
      is: false,
      status: null,
      text: "",
    });
    setLoading({
      is: true,
      text: t("myCommunicationScreen.resend"),
    });
    setTimeout(() => {
      resendHD(id).then((response) => {
        if (response === undefined) {
          setError({
            is: true,
            status: null,
            text: t("myCommunicationScreen.resendError"),
          });
          setLoading({
            is: false,
            text: "",
          });
        } else if (response.status === 200) {
          // resend ok
          setResend(false);
          setError({
            is: false,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        } else {
          setError({
            is: true,
            status: null,
            text: "",
          });
          setLoading({
            is: false,
            text: "",
          });
        }
      });
    }, 1000);
  };

  useEffect(() => {
    setResend(false);
    if(dataComunication.coapStatus?.esito === 'NA'){
      setResend(true);
    }
  }, [dataComunication]);

  return (
    <Col>
      <Card key={dataComunication.codiceComunicazione} id={dataComunication.codiceComunicazione} bg="light" border="secondary">
        <Card.Header>
          <Card.Title data-id-test="dataInvio" >
            {t('communication.dateSent')}{" "}
            {convertDate(dataComunication.dataInvio)}{" "}
            <>{convertTime(dataComunication.dataInvio)}</>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row xs={1} md={2}>
            <Col className="mb-3">
              <span><strong>{t('communication.company.label')}</strong></span>
              <br />
              <span><strong>{t('communication.company.fiscalCode')}</strong>:&nbsp;{dataComunication.datoreLavoro.cfDatoreLavoro}</span>
              <br/>
              <span><strong>{t('communication.company.company')}</strong>:&nbsp;{dataComunication.datoreLavoro.denominazione}</span>
            </Col>
            <Col className="mb-3">
              <span><strong>{t('communication.employee.label')}</strong></span>
              <br />
              <span><strong>{t('communication.employee.fiscalCode')}</strong>:&nbsp;{dataComunication.lavoratore.cfLavoratore}</span>
              <br/>
              <span><strong>{t('communication.employee.name')}</strong>:&nbsp;{dataComunication.lavoratore.nome} {dataComunication.lavoratore.cognome}</span>
            </Col>
          </Row>
          { isHelpdesk ?
          <Row xs={1} md={2} lg={2}>
            <Col className="mb-3">
              <span><strong>{t('communication.coap.label')}</strong></span>
              <br />
              <span><strong>{t('communication.coap.esito')}</strong>:&nbsp;</span><span>{t(dataComunication.coapStatus?.esito)}</span>
            </Col>
            { resend === true ?
            <Col className="mb-3">
              <span><strong>{t('communication.coap.label')}</strong></span>
              <br />
              <ButtonGroup size="sm">
                <Button
                    variant="secondary"
                    key={"resend-" + dataComunication.correlationId}
                    id={"resend-" + dataComunication.correlationId}
                    onClick={() => fnResendHD(dataComunication.correlationId)}
                    disabled={loading.is}
                  >
                    {t("myCommunicationScreen.resend")}
                </Button>
              </ButtonGroup>
            </Col> : 
            <Col className="mb-3">
              <span><strong>{t('communication.coap.label')}</strong></span>
              <br />
              <span>{t("myCommunicationScreen.resendOK")}</span>
            </Col>
            }
          </Row>
          : ""
          }
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md="9" sm="12">
              <ButtonGroup size="sm">
                <Button
                  variant="secondary"
                  key={"show-detail-" + dataComunication.correlationId}
                  id={"show-detail-" + dataComunication.correlationId}
                  onClick={() => clickVisualizzaDettaglio()}
                  disabled={loading.is}
                >
                  {t("myCommunicationScreen.showDetails")}
                </Button>
                <Button
                  variant="primary"
                  key={"save-pdf-" + dataComunication.correlationId}
                  id={"save-pdf-" + dataComunication.correlationId}
                  onClick={() => clickDownloadPDF()}
                  disabled={loading.is}
                >
                  <i className="bi bi-file-earmark-arrow-down"></i>{" "}
                  {t("downloadPdf")}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
}

export default CardCommunication;
