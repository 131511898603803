import "./LoginCIEButton.css";

function LoginCIEButton({sizeButton= "small"}) {

    const cieRequestIdUrl = process.env.NODE_ENV === 'production' ? window.env.CIE_REQUEST_ID_URL : process.env.REACT_APP_CIE_REQUEST_ID_URL;

    return (
        <div className="container-fluid">
            <a href={cieRequestIdUrl} className={"italia-it-button italia-it-button-size-" + sizeButton.charAt(0) + " button-spid"} >
                <span className="italia-it-button-icon">
                    <img src="/img/Logo_CIE_ID.svg" alt="Entra con CIE" />
                </span>
                <span className="italia-it-button-text">Entra con CIE</span>
            </a>
       </div> 
    );
  }
  
  export default LoginCIEButton;
  