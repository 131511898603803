import { Col, Container, Row, Image, Alert } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useGlobalContext } from "../../../context";
import { useEffect, useState } from "react";

function LandingPageScreen() {
  const { t } = useTranslation();
  const { error, clearError } = useGlobalContext();
  const [cieError, setCieError] = useState({
    is: false,
    text: "",
  });

  useEffect(() => {
    setCieError({
      is: error.is ?? false,
      text: error.text ?? "",
    });
    clearError();
  }, []);

  return (
    <Container id="landingPage" className="containerBody" fluid>
      {cieError.is ? (
        <Row>
          <Col>
            <Alert variant="danger">{t('error.cie.message')}<br/>{cieError.text}</Alert>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col lg="8">
          <Trans>{t("landingPage.text")}</Trans>
        </Col>
        <Col lg="4">
          <Image
            src="normativaPrincipale.jpg"
            width="100%"
            alt="Nominativa Principale"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LandingPageScreen;
